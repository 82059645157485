import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, EndpointDisplay, ComparisonTable, SecondaryCallout, PageSubSection, ReferencesBlock, NCCNcallout } from '../../components';
import CSCC from '../../components/StudyDesign/__study-design/cscc';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-629 - Clinical Trial Results - HCP`,
    keywords: `recurrent or metastatic or locally advanced cutaneous squamous cell carcinoma, keynote 629, recurrent or metastatic or locally advanced cutaneous squamous cell carcinoma clinical trial results`,
    description: `Health care professionals may find clinical trial results for KEYNOTE-629 in patients with recurrent or metastatic or locally advanced cutaneous squamous cell carcinoma (cSCC).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/cutaneous-squamous-cell-carcinoma/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠629","description":"Clinical Findings from KEYNOTE⁠-⁠629","trialDesign":"Multicenter, multicohort, nonrandomized, open-label trial that included 2 cohorts that were analyzed separately: 1 for recurrent or metastatic cSCC and 1 for locally advanced cSCC"}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/cutaneous-squamous-cell-carcinoma/","@type":"MedicalAudience","audienceType":"health care professional","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

const NCCNcalloutData = {
    bodyCopy: 'NCCN Guidelines® recommend pembrolizumab (KEYTRUDA) as a CATEGORY 2A, PREFERRED systemic therapy option for patients with locally advanced, recurrent, or metastatic cSCC if curative surgery or curative radiation therapy are not feasible.<sup>2</sup>',
    showBadge: false,
    alt: ""
}
const NCCNcalloutDefinitions = `
    <p>Advanced cSCC = recurrent or metastatic cutaneous squamous cell carcinoma (cSCC) or locally advanced cSCC that is not curable by surgery or radiation.</p>
    <p>NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.</p>
    <p>Category 2A = Based upon lower-level evidence, there is uniform National Comprehensive Cancer Network® (NCCN®) consensus that the intervention is appropriate.</p>
    <p>NCCN = National Comprehensive Cancer Network® (NCCN®).</p>
`


//from /src/util/data/indications.js
// UPDATE THIS TO THE CORRECT INDICATION #
const indicationId = 13;
const jobCode = jobCodes[13].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/cutaneous-squamous-cell-carcinoma/' },
                { text: 'Dosing Options', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;629' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;629' }, 
    { label: 'NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Advanced cSCC'},
]

const SecondayCalloutFootnotes = [
    {
        label: "a.",
        text: "Complete response (CR) = Disappearance of all target lesions.<sup>1</sup>"
    },
    {
        label: "b.",
        text: "Partial response (PR) = At least a 30% decrease in the sum of diameters of target lesions, taking as reference the baseline sum diameters.<sup>1</sup>"
    },
]

const DORtableFootnotes = [
    {
        label: "c.",
        text: "Among responders."
    },
];

const locallyAdvancedORREndpointDisplayFootnotes = [
    {
        label: "d.",
        text: "Complete response (CR) = Disappearance of all target lesions.<sup>1</sup>"
    },
    {
        label: "e.",
        text: "Partial response (PR) = At least a 30% decrease in the sum of diameters of target lesions, taking as reference the baseline sum diameters.<sup>1</sup>"
    },
]

const dorLaComparisonTableFootnotes = [
    {
        label: "f.",
        text: "Among responders"
    },
]

const DORtableDefinitions = "+ Denotes ongoing response.";

const pageReferences = [
    {
        label: "1.",
        text: "ClinicalTrials.gov. Identifier NCT03284424. Study of pembrolizumab (MK-3475) in adults with recurrent/metastatic cutaneous squamous cell carcinoma (cSCC) or locally advanced unresectable cSCC (MK-3475-629/KEYNOTE&#8288;-&#8288;629). https://clinicaltrials.gov/ct2/show/NCT03284424. Published September 15, 2017. Updated February 27, 2023. Accessed June 14, 2023."
    },
    {
        label: "2.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Squamous Cell Skin Cancer V.1.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed July 24, 2023. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">Objective Response Rate and Duration of Response in 159 Patients With Recurrent or Metastatic or Locally Advanced cSCC not Curable by Surgery or Radiation</PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">

                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>

                            <EndpointDisplay title="Objective Response Rate (ORR) - Recurrent or Metastatic Cohort"
                                limitation="In 105 patients treated with KEYTRUDA"
                                cards={
                                    [
                                        {
                                            dataType: 'ORR',
                                            label: 'KEYTRUDA',
                                            rate: '35%',
                                            ci: '95% CI, 26–45',
                                            n: '37/105',
                                            completeResponseRate: '11%<sup>a</sup>',
                                            partialResponseRate: '25%<sup>b</sup>',
                                        }
                                    ]
                                }
                            />

                            <SecondaryCallout 
                                title='87% of patients with recurrent or metastatic cSCC received one or more prior lines of systemic therapy' 
                                alt=""    
                                footnotes={SecondayCalloutFootnotes}
                                definitions={`<p>CI = confidence interval.</p>`}
                            />

                            <ComparisonTable title="Duration of Response (DOR) - Recurrent or Metastatic Cohort<sup>c</sup>"
                                footnotes={DORtableFootnotes}
                                definitions={DORtableDefinitions}
                                data={[
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA',
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Median DOR',
                                            dataPoint: 'Not reached',
                                            dataSubPoint: '(range: 2.7–30.4+ months)'

                                        },
                                    ],
                                    [
                                        {
                                            label: 'Patients with responses lasting ≥6 months',
                                            dataPoint: '76%',
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Patients with responses lasting ≥12 months',
                                            dataPoint: '68%',
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Median follow-up time',
                                            dataPoint: '23.8 months'
                                        },
                                    ]
                                ]} />

                            <EndpointDisplay title="Objective Response Rate (ORR) - Locally Advanced Cohort"
                                limitation="In 54 patients treated with KEYTRUDA"
                                footnotes={locallyAdvancedORREndpointDisplayFootnotes}
                                cards={
                                    [
                                        {
                                            dataType: 'ORR',
                                            label: 'KEYTRUDA',
                                            rate: '50%',
                                            ci: '95% CI, 36–64',
                                            n: '27/54',
                                            completeResponseRate: '17%<sup>d</sup>',
                                            partialResponseRate: '33%<sup>e</sup>',
                                        }
                                    ]
                                }
                            />

                            <SecondaryCallout 
                                title="22% of patients with locally advanced cSCC had received one or more prior lines of systemic therapy"
                                alt=""
                            />

                            <ComparisonTable title="Duration of Response (DOR) - Locally Advanced Cohort<sup>f</sup>"
                            footnotes={dorLaComparisonTableFootnotes}
                                definitions="<p>+ Denotes ongoing response.</p>"
                                data={[
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA',
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Median DOR',
                                            dataPoint: 'Not reached',
                                            dataSubPoint: '(range: 1.0+–17.2+ months)'

                                        },
                                    ],
                                    [
                                        {
                                            label: 'Patients with responses lasting ≥6 months',
                                            dataPoint: '81%',
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Patients with responses lasting ≥12 months',
                                            dataPoint: '37%',
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Median follow-up time',
                                            dataPoint: '13.4 months'
                                        },
                                    ]
                                ]}
                            />

                            <PageSection bgColor="white" title={anchorLinkModelData[2].label}>
                              <NCCNcallout
                                  data={NCCNcalloutData}
                                  definitions={NCCNcalloutDefinitions}
                              />
                            </PageSection>
                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <CSCC />
                            </PageSubSection>

                        </PageSection>

                    </TemplateColumn>
                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
